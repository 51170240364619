/* 5.01 - About Page - Start
================================================== */
.about_image_1 {
  position: relative;
  margin-left: -273px;
  img {
    &:nth-child(2) {
      top: 170px;
      left: 200px;
      max-width: 75px;
      position: absolute;
    }
    &:nth-child(3) {
      right: 40px;
      bottom: 105px;
      max-width: 80px;
      position: absolute;
    }
  }
}

.about_and_case_section {
  padding: 80px 0 81px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  .shape_image_1 {
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    img {
      width: calc(100% - 530px);
      transform: translateX(5px);
    }
  }
}
.about_image_2 {
  gap: 20px;
  display: flex;
  position: relative;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    margin-left: 50px;
  }
  .image_wrap {
    flex: 0 0 auto;
    overflow: hidden;
    border-radius: var(--bs-border-radius);
    @media screen and (min-width: 768px) {
      max-width: 198px;
    }
  }
  .space_line {
    top: 142px;
    left: 218px;
    max-width: 368px;
    position: absolute;
  }
}

.about_funfact_info {
  z-index: 1;
  width: 100%;
  flex: 0 0 auto;
  padding: 20px 10px;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  @media screen and (min-width: 1025px) {
    width: 647px;
  }
  .customer_count {
    > ul {
      margin-bottom: 12px;
      > li {
        width: 50px;
        height: 50px;
        font-size: 15px;
        overflow: hidden;
        line-height: 28px;
        border-radius: 100%;
        align-items: center;
        display: inline-flex;
        color: var(--bs-white);
        justify-content: center;
        border: 2px solid var(--bs-white);
        background-color: var(--bs-primary);
        &:not(:first-child) {
          margin-left: -15px;
        }
      }
    }
  }
  .about_funfact_counter {
    gap: 100px;
    display: flex;
    margin-top: 26px;
    justify-content: end;

    @media screen and (min-width: 768px) {
      gap: 280px;
    }

    .counter_value {
      line-height: 1;
      font-size: 45px;
      font-weight: 700;
      text-align: center;
      color: var(--bs-dark);
      font-family: "Axiforma Bold";
    }
    .funfact_title {
      color: #676767;
      font-size: 15px;
      font-weight: 400;
      font-family: "Axiforma Regular";
    }
  }
  .btn {
    top: 0;
    right: 5px;
    position: absolute;
    border-radius: var(--bs-border-radius);
    @media screen and (min-width: 768px) {
      right: 38px;
    }
  }
  .icon_globe {
    left: 22px;
    bottom: 22px;
    max-width: 40px;
    position: absolute;
    @media screen and (min-width: 768px) {
      left: 43px;
      bottom: 14px;
    }

    &:before,
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      content: "";
      height: 100%;
      position: absolute;
      border-radius: 100%;
      transition: all 0.33s ease;
      animation: ripple 1.5s linear infinite;
      border: 1px solid var(--bs-white);
    }
    &:after {
      animation-delay: 0.9s;
    }
  }
}

.about_image_3 {
  position: relative;
  .image_wrap {
    overflow: hidden;
    border-radius: var(--bs-border-radius);
  }
  .funfact_block {
    left: -154px;
    bottom: 85px;
    position: absolute;
    .funfact_icon {
      background-color: #ffbe16;
    }
  }
}

// About Page
body:has(.page_banner_section + .intro_about_section) .page_banner_section {
  padding-bottom: 394px;
}
.intro_about_section {
  .intro_about_content {
    z-index: 1;
    position: relative;
    margin: -420px 0 60px;
    .image_wrap {
      overflow: hidden;
      border-radius: var(--bs-border-radius-sm);
    }
  }
}

.about-page {
  .page_banner_section {
    background-image: none !important;
  }
  .client_logo_section {
    background-image: none !important;
  }
}

.service-single-page {
  overflow: hidden;
  .process_technology_review_section {
    padding-top: 80px;

    @media (max-width: 991px) {
      padding-top: 30px;
    }

    .decoration_item {
      display: none;
    }
  }
}

.site_footer {
  overflow: hidden;
}
/* 5.01 - About Page - End
================================================== */
