.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0px 100px;
  gap: 30px;
}

.error-msg-1 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 0;
  color: gray;
}

.err-404 {
  font-size: 11rem;
  line-height: 75px;
  color: var(--bs-dark);
}

@media only screen and (min-width: 768px) {
  .not-found-page {
    padding: 100px 0px;
  }
}
